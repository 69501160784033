import { Profile } from '@10d/tend-ui/components/Profile';
import { Logout } from '@10d/tend-ui/icons';
import { Text } from '@10d/tend-ui/typography';
import { Image } from '@10d/tend-ui/ui';
import { Header } from '@10d/tend-ui-header';
import { Stand } from '@10d/tend-ui-header/core/Stand';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useMemo } from 'react';
import { Flex } from '@10d/tend-ui/grid';
import { keycloakAuthorizationProvider } from 'shared/api/keycloakAuthorizationProvider';
import { DecodedToken } from './SaasHeader.types';

export type SaasHeaderProps = {
  productName: string;
};

export const SaasHeader = ({ productName }: SaasHeaderProps) => {
  const token = keycloakAuthorizationProvider.getToken();
  const decodedToken: DecodedToken | null = token ? jwtDecode(token) : null;

  const handleLogout = useCallback(() => {
    keycloakAuthorizationProvider.logout();
  }, []);

  const profileMenu = useMemo(
    () => [
      {
        key: 'logout',
        label: (
          <Text>
            <Logout /> Выйти
          </Text>
        ),
        onClick: handleLogout,
      },
    ],
    [handleLogout],
  );

  const formattedProductName = useMemo(
    () =>
      productName
        .split('.')
        .map((v) => v[0].toUpperCase() + v.slice(1))
        .join('.'),
    [productName],
  );

  return (
    <Header.Root background='blue600'>
      <Flex gap={4} align='center'>
        <Image
          src={'https://cdn.samolet10d.ru/tend-ui/logos/sinfo-gray0.svg'}
          width={20}
          height={20}
        />
        <Header.Logo
          after={
            process.env.STAND_ENVIRONMENT &&
            process.env.STAND_ENVIRONMENT !== 'prod' && (
              <Stand stand={process.env.STAND_ENVIRONMENT} />
            )
          }
        >
          {formattedProductName}
        </Header.Logo>
        <Flex align='center' flex='1' gap={8} justify='flex-end'>
          <Profile
            description={decodedToken?.email}
            title={`${decodedToken?.given_name} ${decodedToken?.family_name}`}
            items={profileMenu}
          />
        </Flex>
      </Flex>
    </Header.Root>
  );
};
