import axios, { AxiosInstance } from 'axios';
import { getAuthorizationProvider } from './authConfig';

class RestApiClient {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create();

    this.client.defaults.baseURL = '/api';

    const provider = getAuthorizationProvider();

    provider.setAuthInterceptor(this.client);
  }
}

export default RestApiClient;
