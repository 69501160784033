import './polyfills';
import { Dialog, Toast } from '@10d/tend-ui/primitives';
import { TendUI } from '@10d/tend-ui/theme';
import { render } from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from 'app/theme';

import { AuthProvider, QueryProvider, RouterProvider } from 'app/providers';
import { sentryInit } from 'shared/lib/sentry';
import { restApiInstance } from 'shared/api';
import App from './app';

sentryInit();
TendUI.init();

render(
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <QueryProvider>
        <RouterProvider>
          <TendUI
            theme='samolet'
            lang='ru'
            client={restApiInstance as typeof TendUI.init.arguments.client}
          >
            <GlobalStyle />
            <Dialog.Styles />
            <Toast.Styles />
            <App />
          </TendUI>
        </RouterProvider>
      </QueryProvider>
    </AuthProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
