import { SegmentedProps } from '@10d/tend-ui/primitives/Segmented';
import { ProfileRole } from './Profile';

export const VIEW_MODE_NAME_MAP: Record<ProfileRole, string> = {
  employee: 'Для сотрудников',
  partner: 'Для контрагентов',
};

export const VIEW_MODE_SEGMENTED_OPTIONS: SegmentedProps['options'] = [
  { label: VIEW_MODE_NAME_MAP['employee'], value: 'employee' },
  { label: VIEW_MODE_NAME_MAP['partner'], value: 'partner' },
];
