import { Header, HeaderProps } from '@10d/tend-ui-header';
import { Notifications } from '@10d/tend-ui-notifications';
import { useCallback, useMemo } from 'react';
import { goToAuth, goToRegister } from 'samolet-oauth2';
import { useProfileDropdownFilterHandler } from 'app/ui/main-layout/hooks';
import { Profile as ProfileModel } from 'entities/profile/model';
import { IS_SAAS } from 'shared/api/authConfig';
import { CURRENT_SERVICE } from '../../MainLayout.consts';
import { SaasHeader } from '../saas-header';
import * as Styled from './Header.styled';

export const MainLayoutHeader = ({ profile }: { profile?: ProfileModel }) => {
  const dropdownHandlers = useProfileDropdownFilterHandler(profile);

  const handleSignIn = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    if (!IS_SAAS) {
      goToAuth({ clientId: __SAMOLET_OAUTH2_PKCE_CLIENT_ID__ });
    }
  }, []);

  const handleSignUp = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    if (!IS_SAAS) {
      goToRegister();
    }
  }, []);

  const headerProfile = useMemo(() => ({ items: dropdownHandlers }), [dropdownHandlers]);

  const headerSlots: HeaderProps['slots'] = useMemo(
    () => ({
      notifications:
        // TODO: Remove container after update tend-ui-notifications version
        profile && (
          <Styled.NotificationsContainer>
            <Notifications />
          </Styled.NotificationsContainer>
        ),
    }),
    [profile],
  );

  const authorization = useMemo(
    () => ({ onSignin: handleSignIn, onSignup: handleSignUp }),
    [handleSignIn, handleSignUp],
  );

  return IS_SAAS ? (
    <SaasHeader productName={CURRENT_SERVICE} />
  ) : (
    <Header
      app={CURRENT_SERVICE}
      stand={process.env.STAND_ENVIRONMENT}
      user={profile}
      authenticated={!!profile}
      profile={headerProfile}
      slots={headerSlots}
      authorization={authorization}
    />
  );
};
