import { KeyCloakAuthProvider, getRealm } from 'samolet-oauth2';
import { getEnvironment } from 'shared/lib/getEnviroment';

const isLocal = getEnvironment().isLocalhost;

export const keycloakAuthorizationProvider = new KeyCloakAuthProvider(
  {
    clientId: 'info-frontend',
    url: isLocal ? 'https://keycloak-saas.yc-stage.samolet10d.ru' : __KEYCLOAK_INIT_URL__,
    realm: isLocal ? 'alpha' : getRealm(),
  },
  {
    useStandardFlow: true,
    enableLogging: !getEnvironment().isProd,
  },
);
