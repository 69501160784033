import React, { useEffect, useState } from 'react';
import { getAuthorizationProvider } from 'shared/api/authConfig';
import { FullContainerSpinner } from 'shared/ui/full-container-spinner';

type AuthProviderType = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderType> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const authorize = async () => {
      try {
        const provider = getAuthorizationProvider();

        await provider.init();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    authorize();
  }, []);

  if (loading) return <FullContainerSpinner />;

  return <>{children}</>;
};
