import { AnalyticsCounters } from '@10d/analytics-counters';
import { setUser, withProfiler } from '@sentry/react';
import { useEffect } from 'react';
import { authStorage, goToAuth } from 'samolet-oauth2';
import queryString from 'query-string';
import { useProfileQuery } from 'entities/profile/api';
import { FullContainerSpinner } from 'shared/ui';
import { eventsSettings } from 'shared/api/metrika';
import { Router } from './routing/Router';
import { MainLayout } from './ui';
import { withErrorBoundary } from './ui/error-boundary';

const App = () => {
  const parsedQueryString = queryString.parse(location.search) as { auth?: boolean };
  const accessToken = authStorage.getJwtAuthParams()?.access;

  if (parsedQueryString?.auth) {
    !accessToken
      ? goToAuth({ clientId: __SAMOLET_OAUTH2_PKCE_CLIENT_ID__ })
      : (location.href = location.href.replace('auth=true', ''));
  }

  const { profile, profileLoading } = useProfileQuery({
    queryParams: { enabled: !!accessToken },
  });

  useEffect(() => {
    if (profile) {
      setUser({
        id: String(profile.id),
        username: profile.username,
        email: profile.email,
      });

      AnalyticsCounters.globalParams({
        user_id: profile.id,
        external_user: profile.role === 'partner' ? 1 : 0,
        user_role: profile.role ?? null,
      });
    }

    AnalyticsCounters.events(eventsSettings);
  }, [profile]);

  if (accessToken && profileLoading) {
    return <FullContainerSpinner />;
  }

  return (
    <MainLayout>
      <Router />
    </MainLayout>
  );
};

export default withProfiler(withErrorBoundary(App));
