import type { IAnalyticsConfig } from '@10d/analytics-counters/types';

export const Events = {
  ServiceDeskSupportLink: 'ServiceDesk:SupportLink',
  SearchClickResultLink: 'Search:Click:ResultLink',
  SearchShowRetrieved: 'Search:Show:Retrieved',
} as const;

export type EventsKeys = keyof typeof Events;
export type EventsValues = (typeof Events)[EventsKeys];

export const eventsSettings: IAnalyticsConfig = {
  [Events.ServiceDeskSupportLink]: {
    ya: {
      event: 'click_service_desk',
      action: 'click_link',
    },
  },
  [Events.SearchClickResultLink]: {
    ya: {
      event: 'search',
      action: 'click_link',
    },
  },
  [Events.SearchShowRetrieved]: {
    ya: {
      event: 'search',
      action: 'search_result',
    },
  },
};
