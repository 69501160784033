/* Object.hasOwn */

if (!Object.hasOwn) {
  (Object as { hasOwn: unknown }).hasOwn = function (
    obj: object,
    prop: PropertyKey,
  ): boolean {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  };
}

export {};
